import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'auction>related_items'
	},

	data: null,

	gql: {
		schema: gql`
			query AuctionRelatedItems(
				$active: Int,
			  $real_estate_id: ID,
				$real_estate_related_id: ID,
				$action_id: ID,
				$type: String
			) {
				request(
					active: $active,
			    real_estate_id: $real_estate_id,
					real_estate_related_id: $real_estate_related_id,
					action_id: $action_id,
					type: $type
				) {
					real_estate_id
					real_estate_related_id
					active
					action_id
					type
				}
			}
		`
	}
};

export default request;
