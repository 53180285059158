import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import styles from './item-list-box.module.scss';
import useLanguage from '../_hooks/useLanguage';
import useRelated from '../_hooks/useRelated';
import useUser from '../_hooks/useUser';
import useOnScreen from '../_hooks/useOnScreen';
import Loader from '../loader/loader';
import Tag from '../tag/tag';

const ItemListBox = ({ layout, row, onBeforeModalOpen, onBeforePromptOpen, handleSubscription, modalData, rememberItem, isMember, addToListSkipped }) => {
	const { t } = useLanguage();
	const { setRelation } = useRelated();
	const [item, setItem] = useState(row);
	const [isTransition, setIsTransition] = useState(false);
	const [expired, setExpired] = useState(false);

	const ref = useRef();
	const isVisible = useOnScreen(ref);

	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [isInViewport, setIsInViewport] = useState(false);

	useEffect(() => {
		if (isVisible && isInViewport === false) {
			setIsInViewport(true);
		}
	}, [isVisible]);

	const { userData, paidAccess } = useUser();

	const [isExposed, setIsExposed] = useState(false);

	// Remember scroll position and item, which were last clicked

	const onBeforeRedirect = () => {
		setIsTransition(true);

		if (process.browser && rememberItem) {
			window.localStorage.setItem('list-scroll-top', JSON.stringify({
				pageYOffset: window.pageYOffset,
				real_estate_id: item.real_estate_id
			}));
		}
	};

	// Relation (saved)

	const onRelationChange = () => {
		setRelation({ type: 'SAVED', real_estate_related_id: item.saved ? item.saved[0] : null, real_estate_id: item.real_estate_id, active: item.saved ? 0 : 1 }, (d, e) => {
			if (d) setItem({ ...item, saved: d.active ? [d.real_estate_related_id] : null });
		});
	};

	// Subscriptions

	const [isSubscribed, setIsSubscribed] = useState(null); // null = unset, true/value = yes, false = no

	useEffect(() => {
		if (item) {
			const subscribed = (item.subscribed !== null && typeof item.subscribed === 'object');
			const { saved } = item;

			// On mount
			if (isSubscribed === null) {
				setIsSubscribed(subscribed);
			}

			// Skip, if item is removed from list (no saved and no subscribed)
			if (addToListSkipped !== null && !subscribed && !saved) {
				addToListSkipped(addToListSkipped);
			}
		}
	}, [item]);

	useEffect(() => {
		if (isSubscribed !== null && typeof modalData[item.real_estate_id] !== 'undefined') { // skip on mount, handle only, if modalData changed and item exist
			setIsSubscribed(Object.keys(modalData[item.real_estate_id]).length > 0);
		}
	}, [modalData]);

	useEffect(() => {
		setItem({ ...item, subscribed: isSubscribed });
	}, [isSubscribed]);

	useEffect(() => {
		if (row.exposed) {
			setTimeout(() => setIsExposed(true), 1500);
			setTimeout(() => setIsExposed(false), 4500);
		}

		if (row.auction_start_countdown_days) {
			setExpired(row.auction_start_countdown_days.charAt(0) === '-');
		}
	}, [row]);

	return (
		<div className={`${styles.default} ${styles[layout] || ''} ${isExposed ? styles.exposed : ''}`} ref={ref}>
			<div>
				<div className={styles.tag}>{item.transaction_nm}</div>
				<div className={styles.actions}>
					<button type="button" className={item.saved ? styles.active : ''} onClick={() => paidAccess(() => onRelationChange())} aria-label="Shrani oglas"><i className={`icon heart ${item.saved ? ' white' : ''}`} /></button>
					{isMember && item.subscribed && <Link href={`/drazba/${item.uri_segment}`}><a className={styles.active}><i className="icon bell-on sm white" /></a></Link>}
					{isMember && userData && userData.user_id === item.owner_user_id && (
						<>
							<Link href={`/profil/vpis-oglasa?id=${item.real_estate_id}`}><a><i className="icon pen" /></a></Link>
							<button type="button" onClick={() => onBeforePromptOpen(item)} aria-label="Odstrani iz mojih oglasov"><i className="icon trash" /></button>
						</>
					)}

					{isMember && (isSubscribed || item.saved) && onBeforePromptOpen && <button type="button" onClick={() => onBeforePromptOpen(item)} aria-label="Odstrani iz shranjenih"><i className="icon trash" /></button>}
				</div>
				<Link href={`/drazba/${item.uri_segment}`}>
					<a onClick={() => onBeforeRedirect()} className={styles.imagePlaceholder}>
						{isInViewport && <img src={item.image_filepath_url} alt="Item img" onLoad={() => setIsImageLoaded(true)} className={`${styles[isImageLoaded ? 'loaded' : '']} ${styles.lazy}`} />}
					</a>
				</Link>
				{isTransition && <Loader small />}
			</div>
			<div className={styles.data}>
				<Link href={`/drazba/${item.uri_segment}`}>
					<a onClick={() => onBeforeRedirect()}>
						<h3>{item.location}</h3>
						<p>{item.specifications}</p>
						<div className={styles.cols}>
							<dl>
								<dt>{t('labelAuctionPrice')}:</dt>
								<dd>{item.price}</dd>
							</dl>
							{item.auction_start_countdown_days ? (
								<dl>
									<dt>{t(expired ? 'labelDate' : 'labelAuctionCountdown')}:</dt>
									<dd>{expired ? item.security_deposit_due_dt : `${item.auction_start_countdown_days} ${t('labelDays')}`}</dd>
								</dl>
							) : null}
						</div>
					</a>
				</Link>
			</div>

			<div className={styles.bottom}>
				{handleSubscription && 	<button type="button" className={`btn i ${(isMember && isSubscribed) ? 'success' : 'dark'}`} onClick={() => onBeforeModalOpen(item)} aria-label="Uredi subscriptions"><i className={`icon white ${isSubscribed ? 'bell-on' : 'bell'}`} />{t(isSubscribed ? 'labelNotifyEnabled' : 'btnAuctionNotifyMe')} {!isMember && <Tag classes="primary">PRO</Tag>}</button>}
			</div>
		</div>
	);
};

ItemListBox.defaultProps = {
	layout: 'grid',
	row: {},
	onBeforeModalOpen: null,
	onBeforePromptOpen: null,
	handleSubscription: false,
	modalData: {},
	rememberItem: false,
	isMember: false,
	addToListSkipped: null // only in use, when items are loaded via ItemList component!
};

ItemListBox.propTypes = {
	layout: PropTypes.string,
	row: PropTypes.shape({}),
	onBeforeModalOpen: PropTypes.func,
	onBeforePromptOpen: PropTypes.func,
	handleSubscription: PropTypes.bool,
	modalData: PropTypes.shape({}),
	rememberItem: PropTypes.bool,
	isMember: PropTypes.bool,
	addToListSkipped: PropTypes.func
};

export default ItemListBox;
