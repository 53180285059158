import useAPI from './useAPI';
import useApp from './useApp';
import requestRelated from '../../requests/mutation/auction/related';
import requestRelatedItems from '../../requests/query/auction/related-items';
import requestSavedRemoveAll from '../../requests/mutation/auction/saved-remove-all';

const useRelated = () => {
	const { query, mutate } = useAPI();
	const { appDispatch } = useApp();

	// Set relation

	const setRelation = (variables, callback) => {
		mutate(requestRelated, variables, (d, e) => {
			if (callback) callback(d, e);
			appDispatch({ type: 'HEADER_PROFILE_CNT_REFRESH_INC' });
		});
	};

	const getRelations = (variables, callback) => {
		query(requestRelatedItems, variables, callback);
	};

	// Remove all saved (relation SAVED & all subscriptions)

	const savedRemoveAll = (variables, callback) => {
		mutate(requestSavedRemoveAll, variables, (d, e) => {
			if (callback) callback(d, e);
			appDispatch({ type: 'HEADER_PROFILE_CNT_REFRESH_INC' });
		});
	};

	return { getRelations, setRelation, savedRemoveAll };
};

export default useRelated;
